import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Implementing a Successful Veteran Hiring Initiative That Attracts Top Talent",
  "author": "Anthony",
  "date": "2018-05-23T05:26:52.000Z",
  "categories": ["Veteran Hiring"],
  "slug": "implementing-a-successful-veteran-hiring-initiative-that-attracts-top-talent",
  "draft": false,
  "meta_title": "Implementing a Successful Veteran Hiring Initiative That Attracts Top Talent"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As companies continually seek talent that contributes loyalty, passion, resilience, and commitment to the workforce, they look to veterans.`}</p>
    <p>{`The values of the military align with many corporate goals, such as being customer-centric, providing a consultative approach, and focusing on collaboration and teamwork. It’s no wonder the Department of Veterans Affairs estimates that by the year 2023, there will be more than 3.5 million veterans in the civilian job market.`}</p>
    <p>{`As companies like yours implement veteran hiring initiatives, it’s crucial that you understand how to optimize your efforts in order to ensure success. This means keeping the cost to hire as low as possible, recruiting top talent, improving retention, and increasing your ROI.`}</p>
    <p>{`In order to quickly and cost-efficiently fill your veteran initiative with the most qualified candidates that will increase ROI, where do you start? The following tips will help you implement a successful veteran hiring initiative that attracts top talent.`}</p>
    <p><strong parentName="p">{`Executive Support`}</strong></p>
    <p>{`It’s important that you have buy-in from senior management when working to hire qualified veterans. This will allow you to conduct research, create unique programs, form alliances, and build visibility as a veteran-friendly company. Without this support, many initiatives fall short of meeting objectives or are phased out as interest and enthusiasm become hard to sustain.`}</p>
    <p><strong parentName="p">{`Skill Translating Savvy`}</strong></p>
    <p>{`Reconnaissance, NCO, Brigade… what do these terms mean? The military uses job codes with descriptions of responsibilities and duties — but most veterans do not have the civilian employee background to intuitively know how those codes translate into civilian roles. If you educate yourself on `}<a parentName="p" {...{
        "href": "https://mailchi.mp/purepost.co/purepostco-recruiter-early-access-registration"
      }}>{`how to translate military skills into civilian roles`}</a>{` — that will likely fit your open job descriptions — you’ll not only help vets understand their true capabilities in the workforce, but you’ll tap into a candidate pool that will help you find extremely qualified individuals for your jobs. Why?`}</p>
    <p>{`Veterans are among the most highly skilled and experienced employees and managers in the U.S. workforce. The jobs within the military are as diverse and varied as in the civilian world. Many veterans have strong leadership and managerial skills because they were given more fiscal and people management responsibility at younger ages than civilian workers. They have been responsible for equipment worth millions of dollars, and for the safety of dozens to hundreds of people. In short, you can hold them accountable.`}</p>
    <p><strong parentName="p">{`Transition Coaching`}</strong></p>
    <p>{`Offer veterans in the market for civilian job support in their transition. You can educate them on the accepted ways and customs of civilian employment and company culture. Provide them with mentors and coaches — like the guidance they got in the military from higher ranks — who can help them identify the differences and learn to adapt to new expectations. Investing in these hires and showing your commitment to their career will also improve retention.`}</p>
    <p>{`Want to learn more about where and how to hire the most qualified veterans for the job? Request a call with our team!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      